import { createContext, useContext, Dispatch, SetStateAction } from 'react';

export const TagContext = createContext<ITagContextValue>(null!);

export const useTag = () => useContext(TagContext);

export interface ITagContextValue {
  activeTag: string;
  setActiveTag: Dispatch<SetStateAction<string>>;
  activeLetter: string;
  setActiveLetter: Dispatch<SetStateAction<string>>;
  onTagClick: (tag: string) => void;
  tagList: string[];
  setTagList: Dispatch<SetStateAction<string[]>>;
  casesWithActiveTag: string[];
  setCasesWithActiveTag: Dispatch<SetStateAction<string[]>>;
  discardTags: () => void;
}

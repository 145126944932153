import { graphql, useStaticQuery } from 'gatsby';
interface ICase {
  [key: string]: { id: string; tag_name: string }[];
}
export const useCaseData: () => ICase = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiCaseTags {
        nodes {
          id
          title
          tags {
            id
            tag_name
          }
        }
      }
    }
  `);

  return data.allStrapiCaseTags.nodes.reduce((acc: any, { title, tags }: ICase) => {
    return { ...acc, ...{ [title.toLowerCase()]: [...tags] } };
  }, {});
};

import React from 'react';
import styles from './tags.module.css';
import clsx from 'clsx';
import { useTag } from './tagContext';
import { ISortedTag } from './tag-types';

interface ITagButtons {
  tags: ISortedTag[];
}

const TagButtons = ({ tags }: ITagButtons) => {
  const { activeTag, activeLetter, setActiveLetter, tagList, setTagList, onTagClick } = useTag();

  return (
    <>
      <ul className={styles.letterList}>
        {tags.map(({ letter, tags }) => (
          <li
            className={
              letter === activeLetter
                ? clsx(styles.letterItem, styles.activeLetter)
                : styles.letterItem
            }
            onClick={() => {
              setActiveLetter(letter);
              setTagList(tags);
            }}
            key={letter}
          >
            {letter.toUpperCase()}
          </li>
        ))}
      </ul>
      <ul className={styles.tagOnLetterList}>
        {tagList &&
          tagList.length > 0 &&
          tagList.map((tag, ind) => (
            <li
              className={
                tag === activeTag
                  ? clsx(styles.tagOnLetterItem, styles.activeTag)
                  : styles.tagOnLetterItem
              }
              onClick={() => {
                onTagClick(tag);
              }}
              key={`${tag}-${ind}`}
            >
              {tag}
            </li>
          ))}
      </ul>
    </>
  );
};
export default TagButtons;

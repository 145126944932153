import * as React from 'react';

interface Props {
  params: ReadonlyArray<string>;
  current: ReadonlyArray<string>;
  children: any;
}

function Show({ params, current, children }: Props) {
  const status = React.useMemo<boolean>(() => {
    return current.some((e) => params.includes(e));
  }, [params, current]);
  return status ? children : null;
}

export default Show;

import React, { memo } from 'react';
import clsx from 'clsx';
import loadable from '@loadable/component';
import { Form } from 'react-final-form';
import compact from 'lodash/fp/compact';
import compose from 'lodash/fp/compose';
import map from 'lodash/fp/map';
import styles from './filter.module.css';

const SERVICES = [
  { label: 'MVP development', value: 'mvp-development' },
  { label: 'Product development', value: 'product-development' },
  { label: 'SaaS development', value: 'saas-development' },
  { label: 'Dedicated team', value: 'dedicated-team' },
  { label: 'Digital transformation', value: 'digital-transformation' },
  { label: 'Mobile app development', value: 'mobile-app-dev' },
  { label: 'Web app development', value: 'web-app-dev' },
  { label: 'DevOps', value: 'devops' },
  { label: 'Team augmentation', value: 'team-augmentation' },
];

const TECHNOLOGIES = [
  { label: 'Node.js', value: 'node' },
  { label: 'React.js', value: 'react' },
  { label: 'React Native', value: 'react-native' },
  { label: 'Gatsby.js', value: 'gatsby' },
  { label: 'AWS', value: 'aws' },
  { label: 'Ruby on Rails', value: 'ruby-on-rails' },
];

const INDUSTRIES = [
  { label: 'Agritech', value: 'agritech' },
  { label: 'EdTech', value: 'edtech' },
  { label: 'Fintech', value: 'fintech' },
  { label: 'Event Management', value: 'event-management' },
  { label: 'Foodtech', value: 'foodtech' },
  { label: 'Ride Sharing', value: 'ride-sharing' },
  { label: 'Proptech', value: 'proptech' },
  { label: 'Recruiting & HR', value: 'recruiting-hr' },
  { label: 'E-commerce', value: 'e-commerce' },
  { label: 'Healthtech', value: 'healthtech' },
  { label: 'Fitness Apps', value: 'fitness-software' },
  { label: 'Martech', value: 'martech' },
  { label: 'Amusement', value: 'amusement' },
];

const init = {
  services: { label: 'All Services', value: 'all services' },
  technologies: { label: 'All Technologies', value: 'all technologies' },
  industries: { label: 'All Industries', value: 'all industries' },
};

export const initFilterValuesList = Object.values(init).map(({ value }) => value);

export interface Props {
  onFilter: (data: ReadonlyArray<string>) => void;
}

interface Option {
  label: string;
  value: string;
}

const Select = loadable(() => import('components/form/select'));

function Filter({ onFilter }: Props) {
  const onSubmit = React.useCallback((value: Record<string, Option>) => {
    compose(
      onFilter,
      compact,
      map((m) => m.value),
      Object.values
    )(value);
  }, []);

  return (
    <div className={styles.container}>
      <Form
        onSubmit={onSubmit}
        initialValues={init}
        render={({ handleSubmit, pristine }) => (
          <form className={clsx('inner-container', styles.form)} onSubmit={handleSubmit}>
            <div className={styles.column}>
              <Select name="services" id="services" options={SERVICES} />
            </div>
            <div className={styles.column}>
              <Select name="technologies" id="technologies" options={TECHNOLOGIES} />
            </div>
            <div className={styles.column}>
              <Select name="industries" id="industries" options={INDUSTRIES} />
            </div>
            <button
              type="submit"
              disabled={pristine}
              className={clsx('btn btn_40 btn__white btn__black_red', styles.apply)}
              data-tip
              data-for="Submit button"
            >
              apply
            </button>
          </form>
        )}
      />
    </div>
  );
}

export default memo(Filter);
export { default as Show } from './show';

import React, { useState, useEffect, useRef } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import clsx from 'clsx';
import styles from './cases.module.css';
import Layout from 'components/layout';
import Filter, { Props, initFilterValuesList } from './components/filter';
import Header from './components/header';
import Tags from 'components/tags';
import CasesList from './cases-list';
import Footer from 'components/layout/footer';
import Booking from 'components/feedback';
import casesList from './preview';
import { makeGroup } from './helpers';
import { meta } from '../../metaData';
import { sortTagList, sortTagsByLetters, getFirstLetter } from '../tags/helpers';
import { TagContext } from 'components/tags/tagContext';
import { ITag } from 'components/tags/tag-types';
import { scrollIntoViewWithOffset } from 'utils/helpers';

const initCases = [
  'mvp-development',
  'product-development',
  'dedicated-team',
  'digital-transformation',
  'react',
  'mobile-app-dev',
];

interface ICases {
  pathContext: {
    tagStack: ITag[];
  };
}

function Cases({ pathContext }: ICases) {
  const [filter, setFilter] = useState<ReadonlyArray<string>>(initCases);
  const [activeTag, setActiveTag] = useState<string>(null!);
  const [activeLetter, setActiveLetter] = useState<string>(null!);
  const [tagList, setTagList] = useState<Array<string>>(null!);
  const [casesWithActiveTag, setCasesWithActiveTag] = useState<Array<string>>(null!);
  const tagsRef = useRef<HTMLDivElement>(null!);
  const breakpoint = useBreakpoint();
  const sortedTagList = sortTagList(pathContext.tagStack);
  const tagsForLetter = sortTagsByLetters(pathContext.tagStack);

  const onTagClick = (tag: string) => {
    setActiveTag(tag);
    const firstLetterOfTag = getFirstLetter(tag);
    if (firstLetterOfTag && activeLetter !== firstLetterOfTag) {
      setActiveLetter(firstLetterOfTag);
      setTagList(tagsForLetter[firstLetterOfTag]);
    }
    const casesWithTag = pathContext.tagStack
      .find((el) => el.tag_name === tag)
      ?.case_tags?.map(({ title }) => title.toLowerCase());
    casesWithTag && setCasesWithActiveTag(casesWithTag);
  };

  const discardTags = () => {
    setActiveTag(null!);
    setActiveLetter(null!);
    setTagList(null!);
    setCasesWithActiveTag(null!);
  };

  useEffect(() => {
    if (activeTag && tagsRef?.current) {
      breakpoint.s
        ? tagsRef.current.scrollIntoView()
        : scrollIntoViewWithOffset('#casesContainer', 60);
    }
  }, [activeTag]);

  const list = React.useMemo(() => {
    return makeGroup(
      casesList.filter((f) => f.params.some((s) => filter.includes(s))),
      2
    );
  }, [filter]);

  const onFilter: Props['onFilter'] = (data) => {
    if (
      data.every((el) => initFilterValuesList.includes(el)) &&
      data.length === initFilterValuesList.length
    ) {
      return setFilter(initCases);
    }
    setFilter(data);
    setActiveTag(null!);
    setCasesWithActiveTag(null!);
  };

  return (
    <Layout title="Cases" navBtnTextColor="var(--sky-blue-color)" metaData={{ main: meta.cases }}>
      <Header subNavigation={() => <Filter onFilter={onFilter} />}>
        <h1 className={styles.headerTitle}>Case Studies</h1>
        <p className={styles.headerSubtitle}>A picture is worth a thousands words.</p>
      </Header>
      <TagContext.Provider
        value={{
          activeTag,
          setActiveTag,
          activeLetter,
          setActiveLetter,
          onTagClick,
          tagList,
          setTagList,
          casesWithActiveTag,
          setCasesWithActiveTag,
          discardTags,
        }}
      >
        <main className={clsx('main')}>
          <section className="section inner" id="casesContainer">
            <Tags tags={sortedTagList} tagBlockStyle={styles.tagBlock} ref={tagsRef} />
            <CasesList list={list} />
          </section>
          <Booking />
        </main>
      </TagContext.Provider>
      <Footer />
    </Layout>
  );
}

export default Cases;

import { ITag, ISortedTag } from './tag-types';

export function getFirstLetter(tag: string) {
  return tag?.trim()?.toLowerCase()?.charAt(0);
}
export function sortTagList(tagList: ITag[]): ISortedTag[] {
  return (
    tagList &&
    tagList
      .reduce((acc: any, el) => {
        const initLetter = getFirstLetter(el.tag_name);

        const elementThisLetter = acc.find((accEl: any) => {
          if (accEl && accEl.letter) return getFirstLetter(accEl.letter) === initLetter;
        });
        if (!elementThisLetter) {
          return [...acc, { letter: initLetter, tags: [el.tag_name] }];
        }
        elementThisLetter?.tags?.push(el.tag_name);
        return acc;
      }, [] as ISortedTag[])
      .sort((a: ISortedTag, b: ISortedTag) => a.letter.localeCompare(b.letter))
  );
}

export function sortTagsByLetters(tagList: ITag[]): ISortTagsByLetters {
  return sortTagList(tagList).reduce((acc: ISortTagsByLetters, { letter, tags }) => {
    return { ...acc, ...{ [letter]: [...tags] } };
  }, {} as ISortTagsByLetters);
}

export function getTagsArray(tag: string, tagArr: ISortedTag[]) {
  return tagArr.find(({ letter }) => letter === getFirstLetter(tag))?.tags;
}

export function getInitIndex(tag: string, tagArr: ISortedTag[]) {
  return tagArr.findIndex(({ letter }) => letter === getFirstLetter(tag));
}
interface ISortTagsByLetters {
  [key: string]: string[];
}

import React, { useState, useEffect } from 'react';
import styles from './cases.module.css';
import clsx from 'clsx';
import casesList from './preview';
import { makeGroup } from './helpers';
import { useTag } from '../tags/tagContext';

interface ICasesList {
  list: {
    name: string;
    params: string[];
    component: () => () => JSX.Element;
  }[][];
}

const CasesList = ({ list }: ICasesList) => {
  const [shownCaseList, setShownCaseList] = useState(list);
  const { casesWithActiveTag } = useTag();

  useEffect(() => {
    if (!!casesWithActiveTag) {
      const newList = makeGroup(
        casesList.filter(({ name }) => casesWithActiveTag?.includes(name.toLowerCase())),
        2
      );
      setShownCaseList(newList);
    } else {
      setShownCaseList(list);
    }
  }, [casesWithActiveTag, list]);

  return (
    <ul className={styles.list}>
      {shownCaseList.map((e) => (
        <li key={e.map((n) => n.name).join('-')} className={clsx(styles.w50)}>
          {e.map((c) => {
            const Component = c.component();
            return <Component key={c.name} />;
          })}
          {e.length === 1 ? <div className={styles.item} /> : null}
        </li>
      ))}
    </ul>
  );
};
export default React.memo(CasesList);

import React, { forwardRef } from 'react';
import styles from './tags.module.css';
import Buttons from './Buttons';
//icons
import { ITags } from './tag-types';
import { useTag } from './tagContext';

const Tags = forwardRef<HTMLDivElement, ITags>(({ tags, tagBlockStyle }, ref) => {
  const { activeTag, discardTags } = useTag();

  return activeTag ? (
    <div className={tagBlockStyle} ref={ref}>
      <p className={styles.back} onClick={discardTags}>
        show all cases
      </p>
      <Buttons tags={tags} />
    </div>
  ) : null;
});

export default React.memo(Tags);
